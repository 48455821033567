import React from 'react';
import styled from 'styled-components';
import {BrowserView, MobileView} from 'react-device-detect';
import AmpifyLogo from './svg/AmpifyLogo';
import {Button} from './Button';

interface StudioRegisterSuccessProps {
  desktopHeading?: string;
  desktopDescription?: string;
  openButtonTarget: string;
  openButton?: string;
  mobileHeading?: string;
  mobileDescription?: string;
  onRedirect: (target: string) => void;
}

const StudioRegisterSuccess = (props: StudioRegisterSuccessProps) => (
  <Wrapper>
    <AmpifyLogo />
    <BrowserView>
      <Header>{props.desktopHeading}</Header>
      <Para>{props.desktopDescription}</Para>
      <Button
        data-test-id="open-studio"
        onClick={() => props.onRedirect(props.openButtonTarget)}
      >
        {props.openButton}
      </Button>
    </BrowserView>
    <MobileView>
      <Header>{props.mobileHeading}</Header>
      <Para>{props.mobileDescription}</Para>
    </MobileView>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 18px;
`;

const Header = styled.h1``;

const Para = styled.p`
  margin-top: 1rem;
  margin-bottom: 1rem;
  line-height: 1.5;
`;

export default StudioRegisterSuccess;
