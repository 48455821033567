import React from 'react';
import {graphql, PageProps} from 'gatsby';
import {isMobile} from 'react-device-detect';
import SEO from '../../components/SEO';
import {STUDIO_CREATED_ACCOUNT} from '../../constants/commands';
import StudioRegisterSuccess from '../../components/StudioRegisterSuccess';
import Helmet from 'react-helmet';
import {
  sendAnalyticsEvent,
  shouldInjectCountly,
  getCountlySrc,
} from '../../utils/countly';
import {getBuildEnvFromData} from '../../utils';
import {RegisterSuccessQuery} from './__generated__/RegisterSuccessQuery';

interface RegisterSuccessProps extends PageProps<RegisterSuccessQuery> {
  onRedirect: (url: string) => void;
}

const RegisterSuccess = (props: RegisterSuccessProps) => {
  const data = props.data.en.edges[0].node;
  const openButtonTarget =
    typeof window !== 'undefined'
      ? STUDIO_CREATED_ACCOUNT + window.location.search
      : STUDIO_CREATED_ACCOUNT;

  const handleRedirect = (url: string) => {
    if (isMobile === false) {
      props.onRedirect(url);
    }
  };

  React.useEffect(() => {
    const timer = setTimeout(() => handleRedirect(openButtonTarget), 1000);
    return () => {
      clearTimeout(timer);
    };
  });

  React.useEffect(() => {
    sendAnalyticsEvent('register-success');
  }, []);

  return (
    <>
      <Helmet>
        {shouldInjectCountly() ? (
          <script
            type="text/javascript"
            src={getCountlySrc(getBuildEnvFromData(props.data))}
          ></script>
        ) : null}
      </Helmet>
      <SEO title={isMobile ? data.mobileHeading : data.desktopHeading} />
      <StudioRegisterSuccess
        {...data}
        onRedirect={handleRedirect}
        openButtonTarget={openButtonTarget}
      />
    </>
  );
};

export const pageQuery = graphql`
  query RegisterSuccessQuery {
    en: allContentfulAmpifyWebRegisterSuccess(
      filter: {node_locale: {eq: "en-GB"}}
    ) {
      edges {
        node {
          metaKeywords
          metaDescription
          desktopHeading
          desktopDescription
          mobileHeading
          mobileDescription
          openButton
        }
      }
    }
    site {
      siteMetadata {
        buildEnv
      }
    }
  }
`;

export default RegisterSuccess;
